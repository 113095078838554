import React from 'react';
import { Panel, Div, Button, Gallery } from '@vkontakte/vkui';

import { startApp } from '../../func/init';

import OneScreen from '../../images/OneScreen.png';

import './style.css';

class Welcome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slideIndex: 0,
			buttonWait: false,
		};
	}

	renderButton = (slideIndex) => {
		const { buttonWait } = this.state
		switch (slideIndex) {
			case 0:
				return (
					<Button
						size='l' 
						onClick={this.startPril}
						disabled={buttonWait}
					>
						Запустить
					</Button>
				)
			default:
				//
		}
	}

	startPril = () => {
		//запросить права.
		//запустить приложение.
		this.setState({
			buttonWait: true
		}, () => {
			startApp(false, false, true)
			this.setState({ buttonWait: false })
		})
	}

	render() {
		let { slideIndex } = this.state
		return (
			<Panel theme="white" id={this.props.id}>

					<Gallery
						slideWidth="100%"
						align="center"
						className="WelcomeGallery"
						slideIndex={slideIndex}
						onChange={slideIndex => this.setState({slideIndex})}
						autoplay={0}
						bullets={false}
					>
						<div className="WelcomeOneBlock">
							<div className="WelcomeTextBlock">
								<div className="WelcomeTitle">
									Калькулятор для подсчета Хлебных Единиц
									<div className="WelcomeDescription pt-5">
										Значительно облегчает жизнь человека с диабетом, помогая определить точное количество Хлебных Единиц в любом продукте!
									</div>
								</div>
							</div>
							<div className="WelcomeImg">
								<img alt="" src={OneScreen}/>
							</div>
						</div>

					</Gallery>
					<Div className="flex_center">
						{ 
							this.renderButton(slideIndex) 
						}
					</Div>

			</Panel>
		)
	}
}

export default Welcome;