import React from 'react';
import { View } from '@vkontakte/vkui';

class Page extends React.Component {
	render() {
		const { activePanel, header, popout } = this.props
		const { id, children, navigator } = this.props
		return (
			<View id={id} activePanel={activePanel} header={header} popout={popout}>
				{React.Children.map(children, (Child) =>
					React.cloneElement(Child, {
						...Child.props,
							navigator: {
								...navigator,
							},
					})
				)}
			</View>
		)
	}
}

export default Page;