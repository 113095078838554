import React from 'react';
import { Input, Div, Button } from '@vkontakte/vkui';
import './style.css';

class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { carb, portion, index } = this.props
		const { onChangeCarb, onChangePortion, onAddProduct, onDelProduct } = this.props
		return (
			<Div className="pt-0">
				<Div className="__Light_onePortion">
					{
						(!index) ?
							<div className="flex_end">
								<Button onClick={onAddProduct} className="pr-0 pl-0" level="tertiary">Добавить продукт</Button>
							</div>
						: 
							<div className="flex_end">
								<Button data-index={index} onClick={onDelProduct} className="pr-0 pl-0" level="tertiary">Удалить продукт</Button>
							</div>
					}
				
					<div className="__Light_onePortion__input">
						<div className="descriptionType">Количество углеводов в 100 грамм продукта</div>
						<Input
							data-index={index}
							type="number"
							min="0"
							max="100"
							name="carb"
							value={carb}
							onChange={onChangeCarb}
						/>
					</div>
				
					<div className="__Light_onePortion__input">
						<div className="descriptionType">Общий вес продукта в граммах</div>
						<Input
							data-index={index}
							type="number"
							min="0"
							name="portion"
							value={portion}
							onChange={onChangePortion}
						/>
					</div>
				
				</Div>
			</Div>
		)
	}
}

export default Product;