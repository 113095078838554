import React from 'react';
import { Input, Div, Button } from '@vkontakte/vkui';
import './style.css';

class ExpertSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { onClickShowMore, onChangeCarbRatio, onChangeInsulinSens, onChangeGlycemiaTarget, onChangeGlycemiaBaseline } = this.props
		const { expert_showMore, expert_carbRatio, expert_insulinSens, expert_glycemiaTarget, expert_glycemiaBaseline } = this.props
		return (
			<Div className="pt-0">
				<Div className="__Light_onePortion">
					{
						(expert_showMore) ?
							<div className="flex_end">
								<Button onClick={onClickShowMore} className="pr-0 pl-0" level="tertiary">Свернуть настройки</Button>
							</div>
						: 
							<div className="flex_end">
								<Button onClick={onClickShowMore} className="pr-0 pl-0" level="tertiary">Развернуть настройки</Button>
							</div>
					}
					{
						(expert_showMore) ?
							<div>
								<div className="__Light_onePortion__input">
									<div className="descriptionType">Углеводный коэффициент (УК)</div>
									<Input
										type="number"
										min="0"
										step="0.1"
										name="expert_carbRatio"
										value={expert_carbRatio}
										onChange={onChangeCarbRatio}
									/>
								</div>
							
								<div className="__Light_onePortion__input">
									<div className="descriptionType">Чувствительность к инсулину (ЧИ)</div>
									<Input
										type="number"
										min="0"
										step="0.1"
										name="expert_insulinSens"
										value={expert_insulinSens}
										onChange={onChangeInsulinSens}
									/>
								</div>
							
								<div className="__Light_onePortion__input">
									<div className="descriptionType">Целевой уровень глюкозы</div>
									<Input
										type="number"
										min="0"
										step="0.1"
										name="expert_glycemiaTarget"
										value={expert_glycemiaTarget}
										onChange={onChangeGlycemiaTarget}
									/>
								</div>
							</div>
						: null
					}

					<div className="__Light_onePortion__input">
						<div className="descriptionType">Исходный уровень глюкозы</div>
						<Input
							type="number"
							min="0"
							step="0.1"
							name="expert_glycemiaBaseline"
							value={expert_glycemiaBaseline}
							onChange={onChangeGlycemiaBaseline}
						/>
					</div>

				</Div>
			</Div>
		)
	}
}

export default ExpertSettings;