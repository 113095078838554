import React from 'react';
import { Root, Epic, Tabbar, TabbarItem } from '@vkontakte/vkui';
import { VKWebAppClose } from './vk'

class Stack extends React.Component {

	constructor(props) {
		super(props);
		const { activePage } = this.props

		this.state = {
			activePage: activePage, 	//текущий вью
			pages: {},					//объект с вью и текущими страницами

			history: [], 				//история activePage + pages
			historyPanel: {}, 			//история переходов по каждому вью.
		};
	}

	/*
		Перехватываем событие нажатия на нативную кнопку назад.
	*/
	__init = () => {
		window.onpopstate = (e) => {
			this.__nativeGoBack()
			return;
		}
	}

	/*
		Записываем начальные страницы в каждом вью
		Записываем тоже самое в историю
	*/
	componentWillMount() {
		this.__init()

		const { children } = this.props;
		React.Children.map(children, (Child) => {
			if (Child) {
				const { props } = Child
				const { id, activePanel } = props
				this.setState((state) => ({
					pages: {
						...state.pages,
						[id]:activePanel
					},
					historyPanel: {
						...state.historyPanel,
						[id]: (state.historyPanel[id]) ? [...state.historyPanel[id], activePanel] : [activePanel]
					},

					history: [{
						activePage: state.activePage,
						pages: {
							...state.pages,
							[id]:activePanel
						},
					}]
				}))				
			}
		})
	}

	/*
		Добавляет запись в History браузера
	*/
	__pushState = (state, title, url) => {
		window.history.pushState(state, title, url)
	}

	/*
		Вызывается при нажатии на нативную кнопку надзад
	*/
	__nativeGoBack = () => {
		const { history, activePage } = this.state
		const lastHit = history[history.length - 2]

		if (history.length === 1) {
			VKWebAppClose()
		}

		this.setState((state) => ({
			...lastHit,
			history: (state.history.length > 1) ? [...state.history.slice(0, -1)] : [...state.history],
			historyPanel: {
				...state.historyPanel,
				[activePage]: (state.historyPanel[activePage].length > 1) ? [ ...state.historyPanel[activePage].slice(0, -1) ] : [ ...state.historyPanel[activePage] ]
			},
		}))
	}

	/*
		Билдим таб бар, устанавливаем иконки и внутреннюю логику
	*/
	__buildBar = (children) => {
		const { activePage } = this.state
		const { itemsLayout } = this.props
		return (
			<Tabbar itemsLayout={itemsLayout}>
				{React.Children.map(children, (Child, key) => (
					(Child) ?
						<TabbarItem
							key={key}
							onClick={this.goPage}
							selected={activePage === Child.props.id}
							data-page={Child.props.id}
							text={Child.props.title || ""}
							children={Child.props.icon || null}
						/>
					: null
				))}
			</Tabbar>
		);
	}

	/*
		Добавляет запись к общей истории
		Добавляет запись к истории панели
	*/
	__setHistory = (panel = false) => {
		this.__pushState({}, "", "")

		const { activePage } = this.state
		this.setState((state) => ({
			historyPanel: {
				...state.historyPanel,
				[activePage]: (panel) ? [...state.historyPanel[activePage], panel] : [...state.historyPanel[activePage]]
			},
			history: [...state.history, {
				activePage: state.activePage,
				pages: {
					...state.pages
				}
			}]
		}))
	}

	/*
		Удаляет последнюю запись истории у вью
		Удаляет последнюю запись из общей истории
	*/
	__removeHystoryLastPanel = (page) => {
		this.setState((state, props) => ({
			historyPanel: {
				...state.historyPanel,
				[page] : (state.historyPanel[page].length > 1) ? [ ...state.historyPanel[page].slice(0, -1) ] : [...state.historyPanel[page]]
			},
			history: (state.history.length > 1) ? [...state.history.slice(0, -1)] : [...state.history]
		}))
	}
	
	/*
		Переход на новый вью
		Добавляет новую запись в историю
	*/
	goPage = (e) => {
		const { page } = e.currentTarget.dataset
		this.setState({ activePage: page }, () => { this.__setHistory() });
	}

	/*
		Переход на новую панель текущего вью
		Добавляет новую запись в историю
	*/
	goPanel = (panel = "") => {
		const { activePage } = this.state
		this.setState((state, props) => ({
			pages: {
				...state.pages,
				[activePage] : panel
			}
		}), () => { this.__setHistory(panel) })
	}

	/*
		Переход на одну панель назад в рамках текущего вью
		Удаляет последнюю запись из истории
	*/
	goPanelBack = () => {
		const { activePage, historyPanel } = this.state
		const lastPanel = historyPanel[activePage][historyPanel[activePage].length - 2]
		this.setState((state) => ({
			pages: {
				...state.pages,
				[activePage]:lastPanel
			},
		}), () => { this.__removeHystoryLastPanel(activePage) })
	}

	render() {
		const { activePage, pages } = this.state
		const { children, isEpic, id } = this.props
		const { __buildBar } = this
		if (isEpic) {
			return (
				<Epic id={id} activeStory={activePage} tabbar={__buildBar(children)}>
					{React.Children.map(children, (Child) =>
						(Child) ?
							React.cloneElement(Child, {
								...Child.props,
								activePanel: pages[activePage],
								navigator: {
									goPage: this.goPage,
									goPanel:this.goPanel,
									goPanelBack: this.goPanelBack,
								},
							})
						: null
					)}
				</Epic>
			)
		} else {
			return (
				<Root activeView={activePage}>
					{React.Children.map(children, (Child) =>
						(Child) ?
							React.cloneElement(Child, {
								...Child.props,
								activePanel: pages[activePage],
								navigator: {
									goPage: this.goPage,
									goPanel:this.goPanel,
									goPanelBack: this.goPanelBack,
								},
							})
						: null
					)}
				</Root>
			)
		}

	}

}

export default Stack;