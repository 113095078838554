import React from 'react';
import { ScreenSpinner, ConfigProvider } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Stack from './components/Navigator/Stack';
import Page from './components/Navigator/Page';

//хелперы
import app from './func/app';

import { go } from './func/init';
import { devLog } from './func/utils';
import { getLocalStorage } from './func/utils';


//стартовые панели
import Light from './panels/Light';
import Expert from './panels/Expert';

//Welcome Panel
import Welcome from './panels/Welcome/Welcome';

//иконки

import Icon24Note from '@vkontakte/icons/dist/24/note';

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scheme: "client_light", //тема оформления client_light, client_dark, bright_light, space_gray
			header: false, //показывает или скрывает хедер для отображения при кастомном хедере на десктопе
			userScope: [], //права пользователя
			isStartInGroup: false, //сервис запущен из группы
			isAdminInGroup: false, //пользователь администратор в сообществе
			goStartPanel: false, //показывать стартовый экран
			isLoad: false, //загрузка приложения
			popout: null, //всплывающие окна
			apiUsersData: [], //информация о пользователях из апи
			apiGroupsData: [], //информация о группах из апи

			breadUnit: (+getLocalStorage('breadUnit')) ? +getLocalStorage('breadUnit') : 12,
			products: (getLocalStorage('products')) ? JSON.parse(getLocalStorage('products')) : [ { carb: 0, portion: 0, } ], //все продукты в массиве
			result: 1.56,
			
			expert_carbRatio: 1.8,
			expert_insulinSens: 1.8,
			expert_glycemiaTarget: 5.9,
			expert_glycemiaBaseline: (+getLocalStorage('expert_glycemiaBaseline')) ? +getLocalStorage('expert_glycemiaBaseline') : 3.4,
			expert_result: 1.56,

			expert_showMore: (getLocalStorage('expert_showMore')) ? JSON.parse(getLocalStorage('expert_showMore')) : true,

			first_session: true,

		};
	}

	componentDidMount() {
		app.setState = (state, callback) => {
			this.setState(state, callback)
		}
		app.state = this.state
		go()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.data !== this.state) {
			app.state = {
				...this.state
			}
		}
	}

	render() {
		devLog(this.state)
		let { isLoad, popout, header, scheme, goStartPanel, breadUnit, result,
		expert_carbRatio, expert_insulinSens, expert_glycemiaTarget, expert_glycemiaBaseline,
		expert_result, products, expert_showMore, first_session } = this.state
		if (isLoad) { //сервис уже получил все даннные и загружен
				return (
					<ConfigProvider scheme={scheme} webviewType="vkapps">
						<Stack activePage="light" itemsLayout="vertical" id="main" isEpic={(!goStartPanel)}>
							<Page id="light" activePanel="Home" header={(!goStartPanel) ? header : false} popout={popout} title="Обычный" icon={<Icon24Note/>}>
								<Welcome id={(goStartPanel) ? `Home` : `Welcome`}/>
								<Light 
									id={(!goStartPanel) ? `Home` : `Welcome`}
									breadUnit={breadUnit}
									products={products}
									result={result}
									first_session={first_session}
								/>
							</Page>

							<Page id="expert" activePanel="Home" header={(!goStartPanel) ? header : false} popout={popout} title="Эксперт" icon={<Icon24Note/>}>
								<Welcome id={(goStartPanel) ? `Home` : `Welcome`}/>
								<Expert 
									id={(!goStartPanel) ? `Home` : `Welcome`}

									breadUnit={breadUnit}
									products={products}
									result={result}

									expert_carbRatio={expert_carbRatio}
									expert_insulinSens={expert_insulinSens}
									expert_glycemiaTarget={expert_glycemiaTarget}
									expert_glycemiaBaseline={expert_glycemiaBaseline}
									expert_result={expert_result}

									expert_showMore={expert_showMore}
								/>
							</Page>
						</Stack>
					</ConfigProvider>
				);					
		} else { // сервис ещё не получил все данные
			return <ScreenSpinner/>
		}
	}
}

export default App;