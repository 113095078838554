import app from './app';
import config from '../config';
import navigation from './navigation';
//import wss from './wss';
import { getParams, getHash, isKeyInObj, setLocalStorage, getLocalStorage } from './utils';
import { getUserInfo, getStartToken, listenVKWebAppUpdateConfig } from './vk';

export function go(callback) {

	listenVKWebAppUpdateConfig((data) => {
		let scheme = data.scheme ? data.scheme : 'client_light';
		app.setState({
			scheme: scheme
		})
	})

	window.onpopstate = (e) => {
		navigation.nativeGoBack()
		return;
	}

	const params = getParams()
	const hash = getHash()

	config.set({
		params: params,
		hash: hash,
		app_id: +params.vk_app_id
	}, () => {
		//wss.init(() => { //если нужно конектится к сокету
			getUserInfo((userData) => {
				getStartToken(params.vk_access_token_settings.split(','), (token) => {
					startApp(userData, token, false)
				})
			})
		//}) 
	})
}

export function startApp(userData, token, ignoreStartPanel = false) {
	const { hash } = config
	const params = {
		vk_group_id: (isKeyInObj(hash, 'g')) ? +hash.g : (isStartInGroup()) ? +config.params.vk_group_id : false,
		feedid: +isKeyInObj(hash, 'f'),
	}

	config.set({
		access_token: (token) ? token.access_token : config.access_token, 
		userData: (userData) ? userData : config.userData
	}, () => {
		firstQuery(params, (token) ? token.access_token : config.access_token, (response) => {
			app.setState((state, props) => ({
				userScope: (token) ? token.scope.split(',') : state.userScope,
				goStartPanel: (ignoreStartPanel) ? 
									false 
								: 
									(isStartPanel()) ? 
										(params.vk_group_id) ? false : true
									:
										false,
				isLoad: true,
				header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true,
				isStartInGroup: (params.vk_group_id) ? true : isStartInGroup(),
				isAdminInGroup: isAdminInGroup(),
				...response
				}), () => {
					if (ignoreStartPanel) {
						setLocalStorage('welcome', 'true')
					}
				})
		})
	})
}

function firstQuery(params = {}, access_token = '', callback = () =>{}) {
	//тут все запросы к беку и все запросы к апи вк
	callback({
		
	})
}

function isAdminInGroup() {
	return (config.params.vk_viewer_group_role === 'admin') ? true : false
}

function isStartPanel() {
	//проверить localstorage
	if (isStartInGroup()) {
		return false
	} else {
		let welcome = getLocalStorage('welcome')
		if (!welcome && config.startPanel) {
			return true
		} else {
			return false
		}
	}
}

function isStartInGroup() {
	return (config.params.vk_group_id) ? true : false
}