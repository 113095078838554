import React from 'react';
import { Panel, Slider, Div } from '@vkontakte/vkui';
import { setLocalStorage } from '../func/utils';
import Header from '../components/Header/Header';
import Product from '../components/Product/Product';
import ExpertSettings from '../components/ExpertSettings/ExpertSettings';

import app from '../func/app';

class Expert extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expert_result_xe: 2,
		};
	}
	
	componentDidMount() {
		this.goResult()
	}

	goResult = () => {
		const { breadUnit, products } = this.props
		const { expert_carbRatio, expert_glycemiaBaseline, expert_glycemiaTarget, expert_insulinSens } = this.props

		const result_xe = products.map((one) => {
			const portion = +one.portion
			const carb = +one.carb
			return ((portion * carb) / 100) / breadUnit
		})

		const result_xe_sum = result_xe.reduce((previousValue, currentValue, index, array) => {
			return previousValue + currentValue;
		})

		const expert_result = result_xe.map((one_xe) => {
			const result = expert_carbRatio * one_xe + (expert_glycemiaBaseline - expert_glycemiaTarget) / expert_insulinSens
			if (result > 0) {
				return result
			} else {
				return 0
			}
		})

		const expert_result_sum = expert_result.reduce((previousValue, currentValue, index, array) => {
			return previousValue + currentValue;
		})

		app.setState({
			result: (+result_xe_sum.toFixed(2) > 0 && isFinite(+result_xe_sum) && +expert_result_sum > 0 && isFinite(+expert_result_sum) ) ? +result_xe_sum.toFixed(2) : 0,
			expert_result: (+expert_result_sum > 0 && isFinite(+expert_result_sum) && +result_xe_sum.toFixed(2) > 0 && isFinite(+result_xe_sum) ) ? +expert_result_sum.toFixed(2) : 0
		})
	
	}

	onChangeBreadUnit = (unit) => {
		app.setState({
			breadUnit: +unit
		}, () => {
			setLocalStorage('breadUnit', +unit)
			this.goResult()
		})
	}
	
	onChangeCarb = (e) => {
		const { value } = e.currentTarget;
		const index = +e.currentTarget.dataset.index;

		app.setState((state) => ({
			products: [...state.products.slice(0, index), {
				...state.products[index],
				carb: (+value > 100) ? 100 : value,
			}, ...state.products.slice(index + 1)]
		}), this.saveProducts)
	}

	onChangePortion = (e) => {
		const { value } = e.currentTarget;
		const index = +e.currentTarget.dataset.index;

		app.setState((state) => ({
			products: [...state.products.slice(0, index), {
				...state.products[index],
				portion: (+value > 100000) ? 100000 : value
			}, ...state.products.slice(index + 1)]
		}), this.saveProducts)
	}

	onChangeCarbRatio = (e) => {
		const { value } = e.currentTarget;
		app.setState({ 
			expert_carbRatio: (+value > 100) ? 100 : value
		}, this.goResult)
	}

	onChangeInsulinSens = (e) => {
		const { value } = e.currentTarget;
		app.setState({ 
			expert_insulinSens: (+value > 100) ? 100 : value
		}, this.goResult)
	}

	onChangeGlycemiaTarget = (e) => {
		const { value } = e.currentTarget;
		app.setState({ 
			expert_glycemiaTarget: (+value > 100) ? 100 : value
		}, this.goResult)
	}

	onChangeGlycemiaBaseline = (e) => {
		const { value } = e.currentTarget;
		app.setState({ 
			expert_glycemiaBaseline: (+value > 100) ? 100 : value
		}, () => {
			setLocalStorage('expert_glycemiaBaseline', (+value > 100) ? 100 : value)
			this.goResult()
		})
	}

	onAddProduct = () => {
		const { products } = this.props
		if (products.length >= 5) {
			console.log('Не больше 5')
		} else {
			app.setState((state) => ({
				products: [...state.products, { carb: 0, portion: 0, }]
			}), this.saveProducts)
		}
	}

	onDelProduct = (e) => {
		const index = +e.currentTarget.dataset.index;
		app.setState((state) => ({
			products: [...state.products.slice(0, index), ...state.products.slice(index + 1)]
		}), this.saveProducts)
	}

	saveProducts = () => {
		const { products } = this.props
		setLocalStorage('products', JSON.stringify(products))
		this.goResult()
	}

	onClickShowMore = () => {
		const { expert_showMore } = this.props
		setLocalStorage('expert_showMore', !expert_showMore)
		app.setState((state) => ({
			expert_showMore: !state.expert_showMore
		}))
	}

	render() {
		const { id, breadUnit, expert_carbRatio, expert_insulinSens, expert_glycemiaTarget, expert_glycemiaBaseline, expert_result, result } = this.props
		const { products, expert_showMore } = this.props

		return (
			<Panel theme="white" id={id}>
				<Header name="Эксперт"/>
					<Div>
						<div className="descriptionType">{`Хлебная единица (ХЕ) - ${breadUnit} грамм углеводов`}</div>				
						<Slider
							step={1}
							min={10}
							max={15}
							value={+breadUnit}
							onChange={this.onChangeBreadUnit}
						/>
					</Div>

					<ExpertSettings
						expert_showMore={expert_showMore}
						onClickShowMore={this.onClickShowMore}

						expert_carbRatio={expert_carbRatio}
						onChangeCarbRatio={this.onChangeCarbRatio}
						expert_insulinSens={expert_insulinSens}
						onChangeInsulinSens={this.onChangeInsulinSens}
						expert_glycemiaTarget={expert_glycemiaTarget}
						onChangeGlycemiaTarget={this.onChangeGlycemiaTarget}
						expert_glycemiaBaseline={expert_glycemiaBaseline}
						onChangeGlycemiaBaseline={this.onChangeGlycemiaBaseline}
					/>

					{
						products.map((item, i) => {
							return (
								<Product
									key={i}
									carb={item.carb}
									portion={item.portion}
									index={i}

									onAddProduct={this.onAddProduct}
									onDelProduct={this.onDelProduct}
									onChangeCarb={this.onChangeCarb}
									onChangePortion={this.onChangePortion}
								/>
							)
						})
					}

					<h1 className="flex_center">Итог: {expert_result} ЕД ({result} ХЕ)</h1>
			</Panel>
		)
	}
}

export default Expert;