import React from 'react';
import { Panel, Slider, Div, Alert, Button, Link } from '@vkontakte/vkui';
import { setLocalStorage } from '../func/utils';
import Header from '../components/Header/Header';
import Product from '../components/Product/Product';

import app from '../func/app';

class Light extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.goResult()
		const { first_session } = this.props
		if (first_session) {
			app.setState({first_session: false}, () => {
				setTimeout(() => {
					app.setState({ popout: this.alertAD() })
				}, 1)
			})
		} 
	}

	alertAD = () => {
		return (
			<Alert
				onClose={() => { app.setState({ popout: null }) }}
				actions={[{
					title: 'Закрыть',
					autoclose: true,
					mode: 'cancel',
				}]}
			>
				<h2>Актуальные новости о сахарном диабете в нашем Telegram-канале!</h2>
				<p>Ежедневное обновление информации о самых актуальных новостях, питании, здоровье и образе жизни при сахарном диабете.</p>
				<Div className="pb-0 pl-0 pr-0">
					<Link target="_blank" href="https://t.me/+CuLMEge2vyJmMjJi">
						<Button size="l" stretched={true} >Перейти</Button>
					</Link>
				</Div>
			</Alert>
		)
	}

	goResult = () => {
		const { breadUnit, products } = this.props
		
		const resArr = products.map((one) => {
			const portion = +one.portion
			const carb = +one.carb
			return ((portion * carb) / 100) / breadUnit
		})

		const result = resArr.reduce((previousValue, currentValue, index, array) => {
			return previousValue + currentValue;
		})

		app.setState({
			result: (+result.toFixed(2) > 0 && isFinite(+result)) ? +result.toFixed(2) : 0,
		})
	}

	onChangeBreadUnit = (unit) => {
		app.setState({
			breadUnit: +unit
		}, () => {
			setLocalStorage('breadUnit', +unit)
			this.goResult()
		})
	}

	onChangeCarb = (e) => {
		const { value } = e.currentTarget;
		const index = +e.currentTarget.dataset.index;

		app.setState((state) => ({
			products: [...state.products.slice(0, index), {
				...state.products[index],
				carb: (+value > 100) ? 100 : value,
			}, ...state.products.slice(index + 1)]
		}), this.saveProducts)
	}

	onChangePortion = (e) => {
		const { value } = e.currentTarget;
		const index = +e.currentTarget.dataset.index;

		app.setState((state) => ({
			products: [...state.products.slice(0, index), {
				...state.products[index],
				portion: (+value > 100000) ? 100000 : value
			}, ...state.products.slice(index + 1)]
		}), this.saveProducts)
	}

	onAddProduct = () => {
		const { products } = this.props
		if (products.length >= 5) {
			console.log('Не больше 5')
		} else {
			app.setState((state) => ({
				products: [...state.products, { carb: 0, portion: 0, }]
			}), this.saveProducts)
		}
	}

	onDelProduct = (e) => {
		const index = +e.currentTarget.dataset.index;
		app.setState((state) => ({
			products: [...state.products.slice(0, index), ...state.products.slice(index + 1)]
		}), this.saveProducts)
	}

	saveProducts = () => {
		const { products } = this.props
		setLocalStorage('products', JSON.stringify(products))
		this.goResult()
	}

	render() {
		let { breadUnit, result } = this.props
		const { products } = this.props
		return (
			<Panel theme="white" id={this.props.id}>
				<Header name="Обычный"/>
			
					<Div>
						<div className="descriptionType">{`Хлебная единица (ХЕ) - ${breadUnit} грамм углеводов`}</div>
						<Slider
							step={1}
							min={10}
							max={15}
							value={+breadUnit}
							onChange={this.onChangeBreadUnit}
						/>
					</Div>
					
					{
						products.map((item, i) => {
							return (
								<Product
									key={i}
									carb={item.carb}
									portion={item.portion}
									index={i}

									onAddProduct={this.onAddProduct}
									onDelProduct={this.onDelProduct}
									onChangeCarb={this.onChangeCarb}
									onChangePortion={this.onChangePortion}
								/>
							)
						})
					}

					<h1 className="flex_center">Итог: {result} ХЕ</h1>

			</Panel>
		)
	}
}

export default Light;